<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">GENERACIÓN DE CALENDARIO DE TRABAJO</h1>
    <vue-step-progress-indicator
      :steps="[
        'Mes',
        'Rango de tiempo',
        'Dias Comunes',
        'No Laborables',
        'Horarios Especiales',        
        'Guardar',
      ]"
      :active-step="activeStep"
      :is-reactive="false"
      @onStepChanged="onStepChanged"
      @onEnterFinalStep="onEnterFinalStep"
      style="justify-content: space-around"
    />
    <div class="row mt-5">
      <div class="col-6 d-flex">
        <b-button
          variant="outline-primary"
          v-show="activeStep > 0"
          @click="backStep"
          :disabled="inProcess"
          >Atras</b-button
        >
      </div>
      <div class="col-6 d-flex justify-content-end">
        <b-button
          variant="outline-primary"
          v-show="activeStep < 5"
          @click="nextStep"
          :disabled="inProcess"
          >Siguiente</b-button
        >
      </div>
    </div>
    <div class="container-step" v-show="activeStep == 0">
      <h2 class="font-weight-bold border-bottom mb-5 pb-3">
        MES DE TRABAJO A GENERAR
      </h2>
      <div class="row">
        <div class="col-12">
          <b-form-group label="Mes" label-for="mesSelect" class="mes-generar">
            <b-form-select
              id="mesSelect"
              v-model="mes"
              :options="meses"
              @change="getIniAndLastDayOfCalendars"
            ></b-form-select>
          </b-form-group>

        </div>
      </div>
    </div>
    <div class="container-step" v-show="activeStep == 1">
      <h2 class="font-weight-bold border-bottom mb-5 pb-3">
        Rango de tiempo para entregas programadas
      </h2>
      <div class="row">
        <div class="col-12">
          <b-form-group label="Rango de tiempo" label-for="rangoTiempo" class="mes-generar">
            <b-form-select
              id="rangoTiempo"
              v-model="rango_entrega"
              :options="opcionesRangoEntrega"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="container-step" v-show="activeStep == 2">
      <h2 class="font-weight-bold border-bottom mb-5 pb-3">
        HORARIO EN DIAS ORDINARIOS
      </h2>
      <div class="row">
        <div class="col-12">
          <table class="tabla-dias">
            <tr>
              <th style="width: 25%">Dia</th>
              <th style="width: 25%">Inicio</th>
              <th style="width: 25%">Fin</th>
              <th style="width: 25%">Entregas/rango de tiempo ({{rango_entrega/3600}}horas)</th>
            </tr>
            <tr v-for="(dia, index) in dias" :key="index">
              <td>{{ dia.text }}</td>
              <td class="celda-timer">
                <b-form-select
                  v-model="dias[index].ini"
                  :options="horas"
                ></b-form-select>
              </td>
              <td class="celda-timer">
                <b-form-select
                  v-model="dias[index].fin"
                  :options="horas"
                ></b-form-select>
              </td>
              <td class="celda-timer">
                <b-form-input
                  v-model.number="dias[index].limite"
                  placeholder="nro entregas"
                  :min="1"
                  type="number"
                  step="1"
                ></b-form-input>
              </td>
            </tr>
          </table>
        </div>
        <p class="mt-4">
          nota: Si no se selecciona ninguna fecha en un dia este
          <strong
            >sera tomado como no laborable ese dia durante todo el mes en todas
            las semanas</strong
          >
        </p>
      </div>
    </div>
    <div class="container-step" v-show="activeStep == 3">
      <h2 class="font-weight-bold border-bottom mb-5 pb-3">
        FECHAS SIN TRABAJO
      </h2>
      <div class="row">
        <div class="col-md-4">
          <label for="fecha-especial-no-laborable">Fecha</label>
          <b-form-datepicker
            id="fecha-especial-no-laborable"
            v-model="diaNoLaborableSeleccionado"
            class="mb-2"
            :min="limiteDaysInCalendars.initial"
            :max="limiteDaysInCalendars.end"
            :date-disabled-fn="dateDisabledNoLaborable"
          ></b-form-datepicker>
        </div>
        <div class="col-md-4 container-boton-no-laborable">
          <b-button variant="primary" @click="addDiaNoLaborable()"
            >Agregar fecha</b-button
          >
        </div>
        <div class="col-md-4">
          <table style="width: 100%" v-if="diasNoLaborables.length > 0">
            <tr style="border-bottom: 1px solid #dddddd">
              <th style="width: 30%; padding: 10px 0px"></th>
              <th style="width: 70%">dia sin reparto</th>
            </tr>
            <tr
              v-for="(diaNoLaborable, index) in diasNoLaborables"
              :key="index"
              style="border-bottom: 1px solid #dddddd"
            >
              <th style="width: 30%; text-align: right; padding: 10px 0px">
                <b-icon
                  icon="x-circle"
                  variant="danger"
                  @click="deleteDiaNoLaborable(index)"
                  scale="1.5"
                  style="margin: 0px 10px; cursor: pointer"
                  v-b-tooltip.hover
                  title="Eliminar"
                ></b-icon>
              </th>
              <th class="dia-no-laborable">
                {{ diaNoLaborable }}
              </th>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="container-step" v-show="activeStep == 4">
      <h2 class="font-weight-bold border-bottom mb-5 pb-3">
        DIAS CON HORARIO ESPECIAL
      </h2>
      <div class="row">
        <div class="col-md-3">
          <label for="fecha-especial">Fecha</label>
          <b-form-datepicker
            id="fecha-especial"
            v-model="diaEspecialSeleccionado"
            class="mb-2"
            :min="limiteDaysInCalendars.initial"
            :max="limiteDaysInCalendars.end"
            :date-disabled-fn="dateDisabled"
          ></b-form-datepicker>
        </div>
        <div class="col-md-3 d-flex" style="flex-wrap: wrap">
          <div class="container-hora-especial">
            <label for="fecha-especial-ini">Hora Inicio</label>
            <b-form-select
              id="fecha-especial-ini"
              v-model="diaEspecialIni"
              :options="horas"
            ></b-form-select>
          </div>
          <div class="container-hora-especial">
            <label for="fecha-especial-fin">Hora Fin</label>
            <b-form-select
              id="fecha-especial-fin"
              v-model="diaEspecialFin"
              :options="horas"
            ></b-form-select>
          </div>
        </div>
        <div class="col-md-3">
          <label for="limite-especial">Entregas/rango de tiempo ({{rango_entrega/3600}}horas)</label>
          <b-form-input
            id="limite-especial"
            v-model.number="diaEspecialLimite"
            placeholder="nro entregas"
            :min="1"
            type="number"
            step="1"
          ></b-form-input>
        </div>
        <div class="col-md-3 pt-4 mb-3">
          <b-button variant="primary" @click="addDiasEspecial"
            >Agregar fecha</b-button
          >
        </div>
      </div>
      <table
        class="mb-5 tabla-dias-especiales"
        v-show="diasEspeciales.length > 0"
      >
        <tr>
          <th></th>
          <th>dia</th>
          <th>hora inicio</th>
          <th>hora fin</th>
          <th>entregas/dia</th>
        </tr>
        <tr v-for="(fecha, index) in diasEspeciales" :key="index">
          <td>
            <b-icon
              icon="x-circle"
              variant="danger"
              @click="deleteDiaEspecial(index)"
              scale="1.5"
              style="margin: 0px 10px; cursor: pointer"
              v-b-tooltip.hover
              title="Eliminar"
            ></b-icon>
          </td>
          <td>{{ fecha.fecha }}</td>
          <td>{{ secondsToHM(fecha.horaIni) }}</td>
          <td>{{ secondsToHM(fecha.horaFin) }}</td>
          <td>{{ fecha.limite }}</td>
        </tr>
      </table>
    </div>    
    <div class="container-step" v-show="activeStep == 5">
      <h2 class="font-weight-bold border-bottom mb-5 pb-3">RESUMEN</h2>
      <div class="container-mes-resumen">
        <p class="subtitulo-resumen">Mes a programar</p>
        <span>{{ mes ? mes.caption.toUpperCase() : "" }}</span>
      </div>
      <div class="container-mes-comunes">
        <p class="subtitulo-resumen">Dias ordinarios</p>
        <div v-for="(dia, index) in diasNoNull" :key="index">
          <span
            >{{ dia.text }} de {{ secondsToHM(dia.ini) }} a
            {{ secondsToHM(dia.fin) }} - {{ dia.limite }} entregas/rango de tiempo ({{rango_entrega/3600}})horas</span
          >
        </div>
        <div v-if="diasNoNull.length == 0" style="margin: 10px 0px; color: red">
          No hay programación para dias ordinarios
        </div>
      </div>
      <div class="container-mes-no-laborables">
        <p class="subtitulo-resumen">Dias donde no se realizara entrega:</p>
        <div v-for="(dia, index) in diasNoLaborables" :key="index">
          <span>{{ $moment(dia).format("DD-MM-yyyy") }}</span>
        </div>
        <div
          v-if="diasNoLaborables.length == 0"
          style="margin: 10px 0px; color: red"
        >
          No hay programación para dias sin trabajo
        </div>
      </div>
      <div class="container-mes-especiales">
        <p class="subtitulo-resumen">Dias con horario especial:</p>
        <div v-for="(dia, index) in diasEspeciales" :key="index">
          <span
            >{{ $moment(dia.fecha).format("ddd") }}
            {{ $moment(dia.fecha).format("DD-MM-yyyy") }} de
            {{ secondsToHM(dia.horaIni) }} a {{ secondsToHM(dia.horaFin) }} -
            {{ dia.limite }} entregas/rango de tiempo ({{rango_entrega/3600}})horas</span
          >
        </div>
        <div
          v-if="diasEspeciales.length == 0"
          style="margin: 10px 0px; color: red"
        >
          No hay programación para dias especiales
        </div>
      </div>
      <div class="container-mes-resumen">
        <p class="subtitulo-resumen">Rango de tiempo para entregas</p>
        <span>{{ this.rango_entrega/3600 }} Horas</span>
      </div>
      <div class="mt-5 mb-5">
        <b-button
          variant="primary"
          @click="createCalendar"
          :disabled="inProcess"
          >Ejecutar Programación</b-button
        >
        <b-spinner
          variant="primary"
          label="Spinning"
          v-if="inProcess"
          class="ml-3"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { Helpers } from "@/mixins/Helpers";
import VueStepProgressIndicator from "vue-step-progress-indicator";
import EventBus from "@/services/eventBus.js";
import CalendarioService from "@/services/calendario.service.js";
export default {
  name: "CalendarGenerator",
  components: {
    VueStepProgressIndicator,
  },
  mixins: [Helpers],
  data() {
    return {
      calendarioService: null,
      activeStep: 0,
      meses: [],
      mes: null,
      dias: [
        { text: "Domingo", value: 0, ini: null, fin: null, limite: null },
        { text: "Lunes", value: 1, ini: null, fin: null, limite: null },
        { text: "Martes", value: 2, ini: null, fin: null, limite: null },
        { text: "Miercoles", value: 3, ini: null, fin: null, limite: null },
        { text: "Jueves", value: 4, ini: null, fin: null, limite: null },
        { text: "Viernes", value: 5, ini: null, fin: null, limite: null },
        { text: "Sabado", value: 6, ini: null, fin: null, limite: null },
      ],
      opcionesRangoEntrega: [
        { text: "1 Hora", value: 3600},
        { text: "2 Horas", value: 7200},
        { text: "3 Horas", value: 10800},
        { text: "4 Horas", value: 14400},        
      ],
      limiteDaysInCalendars: {
        initial: null,
        end: null,
      },
      diaNoLaborableSeleccionado: null,
      diasNoLaborables: [],
      diaEspecialSeleccionado: null,
      diaEspecialIni: null,
      diaEspecialFin: null,
      diaEspecialLimite: null,
      horas: [],
      intervalosDeHoras: process.env.VUE_APP_INTERVALO_TIEMPO,
      diasEspeciales: [],
      inProcess: false,
      rango_entrega:null
    };
  },
  created() {
    this.calendarioService = new CalendarioService();
  },
  mounted() {
    this.generarMesesSeleccionables();
    this.generarHorasSeleccionables();
  },
  methods: {
    onStepChanged(evento) {
      console.log("cambio el paso", evento);
      this.activeStep = evento;
    },
    onEnterFinalStep(evento) {
      console.log("paso final", evento);
    },
    backStep() {
      this.activeStep--;
    },
    nextStep() {
      if (this.activeStep == 0 && !this.mes) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debe seleccionar un mes de la lista",
        });
        return false;
      }
      if (this.activeStep == 1 && !this.rango_entrega) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debe seleccionar un rango de tiempo en las entregas",
        });
        return false;
      }
      if (this.activeStep == 2 && !this.verificarDiasComunes().value) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.verificarDiasComunes().mensaje,
        });
        return false;
      }      
      this.activeStep++;
    },
    async generarMesesSeleccionables() {
      this.meses = [];
      for (let index = 0; index < 3; index++) {
        let fecha = this.$moment().add(index, "months");
        await this.calendarioService
          .elMesTienePedidos({
            mes: fecha.month(),
            year: fecha.year(),
          })
          .then((result) => {
            if (!result.data.data) {
              this.meses.push({
                text: fecha.format("MMMM - yyyy"),
                value: {
                  caption: fecha.format("MMMM"),
                  mes: fecha.month(),
                  year: fecha.year(),
                },
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    generarHorasSeleccionables() {
      let intervalos = Math.floor(86400 / this.intervalosDeHoras);
      this.horas = [
        {
          text: "",
          value: null,
        },
      ];
      for (let index = 0; index < intervalos; index++) {
        this.horas.push({
          text: this.secondsToHM(index * this.intervalosDeHoras),
          value: index * this.intervalosDeHoras,
        });
      }
    },
    addDiaNoLaborable() {
      if (
        !this.diasNoLaborables.includes(this.diaNoLaborableSeleccionado) &&
        this.diaNoLaborableSeleccionado
      ) {
        this.diasNoLaborables.push(this.diaNoLaborableSeleccionado);
        this.diaNoLaborableSeleccionado = null;
      }
    },
    deleteDiaNoLaborable(index) {
      this.diasNoLaborables.splice(index, 1);
    },
    deleteDiaEspecial(index) {
      this.diasEspeciales.splice(index, 1);
    },
    addDiasEspecial() {
      if (!this.verificarDiasEspeciales().value) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.verificarDiasEspeciales().mensaje,
        });
        return false;
      }
      this.diasEspeciales.push({
        fecha: this.diaEspecialSeleccionado,
        horaIni: this.diaEspecialIni,
        horaFin: this.diaEspecialFin,
        limite: parseInt(this.diaEspecialLimite),
      });
      this.diaEspecialSeleccionado = null;
      this.diaEspecialIni = null;
      this.diaEspecialFin = null;
      this.diaEspecialLimite = null;
    },
    getIniAndLastDayOfCalendars() {
      const mesForDate = this.mes.mes + 1;
      const diasTotales = this.$moment(
        `${this.mes.year}-${mesForDate}`,
        "YYYY-MM"
      ).daysInMonth();
      this.limiteDaysInCalendars.initial = `${this.mes.year}-${
        mesForDate < 10 ? "0" : ""
      }${mesForDate}-01`;
      this.limiteDaysInCalendars.end = `${this.mes.year}-${
        mesForDate < 10 ? "0" : ""
      }${mesForDate}-${diasTotales}`;
      this.diaNoLaborableSeleccionado = null;
      this.diasNoLaborables = [];
      this.diaEspecialSeleccionado = null;
      this.diaEspecialIni = null;
      this.diaEspecialFin = null;
      this.diaEspecialLimite = null;
      this.diasEspeciales = [];
    },
    verificarDiasComunes() {
      let correcto = true;
      let mensaje = "";
      this.dias.map((item) => {
        if ((item.ini && !item.fin) || (item.fin && !item.ini)) {
          correcto = false;
          mensaje =
            "Debe completar hora de inicio y fin en los dias que se trabajara";
          return { value: correcto, mensaje };
        }
      });
      this.dias.map((item) => {
        if (item.ini > item.fin) {
          correcto = false;
          mensaje = "La hora de inicio debe ser menor a la hora fin";
          return { value: correcto, mensaje };
        }
      });
      this.dias.map((item) => {
        if (item.ini && item.fin && !item.limite) {
          correcto = false;
          mensaje = "Se debe indicar un limite de entregas";
          return { value: correcto, mensaje };
        }
      });
      return { value: correcto, mensaje };
    },
    verificarDiasEspeciales() {
      if (
        !this.diaEspecialSeleccionado ||
        !this.diaEspecialIni ||
        !this.diaEspecialFin ||
        !this.diaEspecialLimite
      ) {
        return { value: false, mensaje: "Debe completar todos los campos" };
      }
      if (this.diaEspecialIni >= this.diaEspecialFin) {
        return {
          value: false,
          mensaje: "La hora de inicio debe ser menor a la hora fin",
        };
      }
      return { value: true, mensaje: "" };
    },
    dateDisabled(ymd, date) {
      const listOfDaysNoLaborables = this.diasNoLaborables.map((item) => {
        return this.$moment(item).format("DD");
      });
      const listOfDaysEspeciales = this.diasEspeciales.map((item) => {
        return this.$moment(item.fecha).format("DD");
      });
      const day = this.$moment(date).format("DD");
      return listOfDaysNoLaborables.concat(listOfDaysEspeciales).includes(day);
    },
    dateDisabledNoLaborable(ymd, date) {
      const listOfDaysEspeciales = this.diasEspeciales.map((item) => {
        return this.$moment(item.fecha).format("DD");
      });
      const listOfDaysNoLaborables = this.diasNoLaborables.map((item) => {
        return this.$moment(item).format("DD");
      });
      const day = this.$moment(date).format("DD");
      return listOfDaysEspeciales.concat(listOfDaysNoLaborables).includes(day);
    },
    createCalendar() {
      const listOfDaysEspeciales = this.diasEspeciales.map((item) => {
        return this.$moment(item.fecha).format("DD");
      });
      const listOfDaysNoLaborables = this.diasNoLaborables.map((item) => {
        return this.$moment(item).format("DD");
      });
      let arrayDaysToRequest = [];
      const year = this.mes.year;
      const mes = this.mes.mes;
      let dia = 1;
      let diaVerificacion = "";
      let fecha = "";
      let fechaVerificacion = "";
      do {
        fecha = `${year}-${(mes + 1 < 10 ? "0" : "") + (mes + 1)}-${
          (dia < 10 ? "0" : "") + dia
        }`;
        diaVerificacion = `${(dia < 10 ? "0" : "") + dia}`;

        if (listOfDaysEspeciales.includes(diaVerificacion)) {
          let diaEspecial = this.diasEspeciales.filter((item) => {
            return (
              item.fecha ===
              `${year}-${(mes + 1 < 10 ? "0" : "") + (mes + 1)}-${
                (dia < 10 ? "0" : "") + dia
              }`
            );
          });
          if (diaEspecial.length === 1) {
            arrayDaysToRequest.push({
              fecha: diaEspecial[0].fecha,
              hora_inicio: diaEspecial[0].horaIni,
              hora_fin: diaEspecial[0].horaFin,
              cantidad_limite: diaEspecial[0].limite,
              type_day:'especial'
            });
          }
        } else if (!listOfDaysNoLaborables.includes(diaVerificacion)) {
          let dayOfWeek = this.$moment(fecha).day();
          let dataOfDayOfWeek = this.dias.filter((item) => {
            return (
              item.value === dayOfWeek && item.ini && item.fin && item.limite
            );
          });
          if (dataOfDayOfWeek.length === 1) {
            arrayDaysToRequest.push({
              fecha: fecha,
              hora_inicio: dataOfDayOfWeek[0].ini,
              hora_fin: dataOfDayOfWeek[0].fin,
              cantidad_limite: dataOfDayOfWeek[0].limite,
              type_day:'normal'
            });
          }
        }
        dia++;
        fechaVerificacion = `${year}-${(mes + 1 < 10 ? "0" : "") + (mes + 1)}-${
          (dia < 10 ? "0" : "") + dia
        }`;
      } while (this.$moment(fechaVerificacion).isValid());
      const dataRequest = {
        mes: this.mes.mes,
        year: this.mes.year,
        fechas: arrayDaysToRequest,
        rango_entrega:this.rango_entrega
      };
      this.inProcess = true;
      this.calendarioService
        .createCalendar(dataRequest)
        .then((result) => {
          if (result.data.status === 200 && result.data.data) {
            if (result.data.errores.length === 0) {
              EventBus.$emit("MOSTRAR_TOAST", {
                title: "Felicidades",
                variant: "success",
                message:
                  "El calendario de entregas se generó satisfactoriamente",
              });
            } else {
              let errores = result.data.errores.join(" / ");
              EventBus.$emit("MOSTRAR_TOAST", {
                title: "Felicidades",
                variant: "success",
                message: `El calendario arrojo error en las siguientes fechas: ${errores}`,
              });
            }
            this.mes = null;
            this.diaNoLaborableSeleccionado = null;
            this.diasNoLaborables = [];
            this.diaEspecialSeleccionado = null;
            this.diaEspecialIni = null;
            this.diaEspecialFin = null;
            this.diaEspecialLimite = null;
            this.diasEspeciales = [];
            this.activeStep = 0;
          }
          if (result.data.status != 200 && !result.data.data) {
            let errores = result.data.errores.join(" / ");
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "warning",
              message: `Error al generar calendario: ${errores}`,
            });
          }
          this.inProcess = false;
        })
        .catch((err) => {
          console.error(err);
          this.inProcess = false;
        });
    },
  },
  computed: {
    diasNoNull() {
      return this.dias.filter((item) => {
        return item.ini && item.fin && item.limite;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding: 0px 300px;
}
.tabla-dias {
  width: 80%;
  margin: 0px auto;
}

.container-step {
  width: 100%;
  margin-top: 60px;
}
.mes-generar {
  width: 50%;
  margin: 0px auto;
}
.dia-no-laborable {
  font-size: 20px;
  width: 70%;
}
.container-boton-no-laborable {
  align-items: flex-start;
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
}
.container-hora-especial {
  width: 50%;
  padding: 0px 5px;
}
.tabla-dias-especiales {
  width: 40%;
  margin: 0px auto;
  margin-top: 40px;
}
.tabla-dias-especiales tr {
  border-bottom: 1px solid #dddddd;
}

.tabla-dias-especiales tr th {
  width: 20%;
  padding: 10px 0px;
}
.tabla-dias-especiales tr td {
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0px;
}
.subtitulo-resumen {
  margin: 0px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background-color: #e8e8e8;
  margin-top: 5px;
}
@media (max-width: 1400px) {
  .container-cental {
    padding: 0px 10px;
  }
  .tabla-dias {
    width: 100%;
    margin: 0px auto;
  }
  .celda-timer {
    padding: 10px 0px;
  }
}
@media (max-width: 700px) {
  .mes-generar {
    width: 100%;
  }
  .container-boton-no-laborable {
    justify-content: center;
  }
  .container-hora-especial {
    width: 100%;
    padding: 5px 5px;
  }
  .tabla-dias-especiales {
    width: 100%;
  }
  .tabla-dias tr th {
    font-size: 11px;
  }
  .tabla-dias-especiales tr td {
    font-size: 15px;
    font-weight: bold;
    padding: 10px 0px;
  }
  .tabla-dias-especiales tr th {
    font-size: 14px;
  }
  .tabla-dias-especiales tr td {
    font-size: 14px;
  }
}
</style>
